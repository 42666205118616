import { defineStore } from 'pinia';

import {
  CartApiItem,
  CartApiPayload,
  CartApiResponse,
  CheckoutAddressDetails,
  CheckoutApiResponse,
  CheckoutDeliveryMethod,
  CheckoutPaymentMethod,
  OrderProduct,
  OrderProposal,
  PurchaseResponse,
  UserProfile,
  UserProfileAddress
} from '~/constants/types/norce';
import { useUiStore } from '~/store/ui';
import useApiFetch from '~/composeables/useApiFetch';
import { createDataLayerItem, sendDataLayer } from '~/composeables/useTracking';
import { ProductVariantModel } from '~/models/productVariant';
import { useUserStore } from '~/store/user';
import useStatefulCookie from '~/composeables/useStatefulCookie';
import { NorceInfoTypesCodes, NorceBasketTypes } from '~/constants/norceCodes';

import aa from 'search-insights';
import { ProductModel } from '~/models/product';
import useLinkReplacer from '~/composeables/useLinkReplacer';
import * as Sentry from '@sentry/vue';

const emptyCart = {
  id: '',
  items: [],
  summary: {
    discount: '',
    discountDisplay: '',
    price: '',
    priceDisplay: '',
    priceBeforeVat: '',
    priceBeforeVatDisplay: '',
    shipping: '',
    shippingDisplay: '',
    shippingBeforeVat: '',
    shippingBeforeVatDisplay: '',
    points: -1,
    usedPoints: -1,
    vat: '',
    vatDisplay: '',
    subtotal: '',
    subtotalDisplay: '',
    subtotalBeforeVat: '',
    subtotalBeforeVatDisplay: '',
  },
} as CartApiResponse;

export const customerSuccessItemFilter = (item: CartApiItem | OrderProduct) => {
  return !['30000', '30001'].includes(item.partNo);
};

type multiAddToCart = {
  partNo: string;
  quantity: number;
  product?: CartApiItem | ProductVariantModel | OrderProduct | null
}

export type UpdateWithLineNo = {partNo: string, quantity: number, lineNo: number}

export const useCartStore = defineStore('cartStore', {
  state: () => ({
    cartId: '',
    cartItems: 0, // save separatly, easier to watch
    cart: emptyCart as CartApiResponse,
    isLoading: false, // for standard cart
    alternativeCart: emptyCart as CartApiResponse, // load another cart than standard, used for pointShop and checkout finished
    isAlternativeLoading: false,
    currentlyBuying: null as ProductModel | null,
    isBuyingUpsellProduct: false,
    currentlyBuyingQuantity: 0,
    currentlyBuyingPriceListLocked: false,
    justAddedPartNo: '',
    lastUpdate: 0,
    shippingAddress: {
      companyName: '',
      firstName: '',
      lastName: '',
      street: '',
      zip: '',
      city: '',
      country: '',
      phone: '',
      email: '',
    } as CheckoutAddressDetails,
    billingAddress: {
      companyName: '',
      firstName: '',
      lastName: '',
      street: '',
      zip: '',
      city: '',
      country: '',
      phone: '',
      email: '',
    } as CheckoutAddressDetails,
    payerAddress: {
      companyName: '',
      firstName: '',
      lastName: '',
      street: '',
      zip: '',
      city: '',
      country: '',
      phone: '',
      email: '',
    } as CheckoutAddressDetails,
    deliveryMethods: [] as CheckoutDeliveryMethod[],
    paymentMethods: [] as CheckoutPaymentMethod[],
    buyerCompanyName: null as string|null,
    loadingCart: true,
    loadingCheckout: true,
    loadingPurchase: false,
    hasAttemptedPurchase: false,
    acceptedNewsletter: false,
    pointsUsed: 0,
    pointsAvailable: -1,
    addedToCartCount: 0,
    deboucedAddToCart: [] as multiAddToCart[],
    deboucedTimer: null as any,
  }),
  getters: {
    items(state) {
      return state.cart?.items ?? [];
    },
    // returns number of products bought by points
    pointStoreItemsInCart(state) {
      return state.alternativeCart.items.filter((f) => {
        const infoType = f.infoTypes.findIndex((f2) => f2.name === 'tester_points_value');
        return infoType > -1;
      }).reduce((a, c) => a + c.quantity, 0);
    },
    // returns the item in alternative cart that is bought from pointstore
    pointStoreItems(state) {
      return state.alternativeCart.items.filter((f) => f.infoTypes.some((s) => s.name === 'tester_points_value'));
    },
    hasDiscount(state) {
      return state.cart?.summary?.discount && state.cart?.summary?.discount !== '0';
    },
    appliedDiscountNames(state): string {
      return state.cart?.appliedPromotions?.map(ap => ap.discountCode)?.join(',') ?? '';
    },
    hasSelectedPaymentMethod(state): boolean {
      return state.cart?.paymentInfo?.hasSelectedMethod ?? false;
    },
    isNetsEasyCheckout(state): boolean {
      return state.paymentMethods.find(method => method.selected)?.id === 41 || false;
    },
    isOrderProposalBasket(state): boolean {
      return state.cart?.infoTypes?.find(i => i.name === NorceInfoTypesCodes.BasketType)?.value === NorceBasketTypes.OrderProposal || false;
    },
    mayAddMoreForPartNo: (state) => (partNo: string) => {
      const findLineNo = state.cart.items.find((f) => f.partNo === partNo);
      if (findLineNo) {
        const today = new Date().toISOString().split('T')[0];
        if (findLineNo.stockStatus.estimatedDelivery && findLineNo.stockStatus.estimatedDelivery > today) {
          return true;
        }
        return findLineNo.stockStatus.value > findLineNo.quantity;
      }

      return true;
    },
    deliveryLeadTime: (state) => {
      const itemWithLongestLeadtime = state.cart?.items.reduce((prev: CartApiItem | null, current: CartApiItem) => {
        const currentLeadTime = current.stockStatus.leadtimeDayCount;

        if (currentLeadTime === undefined) {
          return prev;
        }

        if (prev === null || (currentLeadTime !== null && currentLeadTime > prev.stockStatus.leadtimeDayCount!)) {
          return current;
        }

        return prev;
      }, null);

      return itemWithLongestLeadtime?.stockStatus?.leadtimeDayCount || 1;
    },
    getQuantityFromPartNo: (state) => (partNo: string): number => {
      const find = state.cart.items.find((f) => f.partNo === partNo);
      if (find) {
        return find.quantity;
      }
      return 0;
    },
    getLineNoFromPartNo: (state) => (partNo: string): number => {
      const find = state.cart.items.find((f) => f.partNo === partNo);
      if (find) {
        return find.lineNo;
      }
      return -1;
    },
  },
  actions: {
    async loadAlternativeBasket(basketId: string) {
      const { apiGet, handleApiError } = useApiFetch();
      const res = await apiGet<CartApiResponse>(`/basket/${basketId}?storeBasket=0`, null, false);
      if (res) {
        if (res.allowPointsPurchase) {
          this.pointsAvailable = res.summary.points;
          this.pointsUsed = res.summary.usedPoints;
          if (this.pointsUsed > 0) {
            // removes reminder cookie, if shopping started
            const pointShopReminder = useCookie('pointShopReminder');
            pointShopReminder.value = null;
          }
        }
        this.alternativeCart = res;
        return true;

      } else {
        handleApiError();
        return false;
      }
    },
    // this adds/subtract
    changePointsUsed(payload: number) {
      this.pointsUsed += payload;
    },
    setCurrentlyBuying(payload: ProductModel, isUpsell?: boolean) {
      this.currentlyBuying = payload;
      this.isBuyingUpsellProduct = !!isUpsell;
    },
    async setOrderProposalAsCart(orderProposal: OrderProposal) {
      this.loadingCart = true;
      this.cartId = orderProposal.basketId;
      const cartId = useCookie('cartId', { maxAge: 3600 * 24 * 182, path: '/' + useRoute().path?.split('/').filter((str) => str.length).shift() });
      cartId.value = this.cartId;

      setTimeout(async() => {
        await this.getCart(false);
      }, 500);
    },
    setCartFromId(id: string) {
      if (!useRuntimeConfig().public.fetchStoredBasket) {
        return;
      }
      const cartId = useCookie('cartId', { maxAge: 3600 * 24 * 182, path: '/' + useRoute().path?.split('/').filter((str) => str.length).shift() });
      cartId.value = id;
      this.cartId = id;

      setTimeout(async() => {
        this.getCart(false);
      }, 200);
    },
    setAddedToCartCount(payload: number) {
      this.addedToCartCount = payload;
    },
    /**
     *
     * @param createIfNotExists - creates a new cart if there's no cartId cookie
     */
    async getCart(createIfNotExists = true) {
      if (!useUserStore().isLoggedIn) {
        this.isLoading = false;
        return;
      }
      this.loadingCart = true;
      const { apiGet, apiPost } = useApiFetch();

      const cartId = useCookie('cartId', { maxAge: 3600 * 24 * 182, path: '/' + useRoute().path?.split('/').filter((str) => str.length).shift() });

      if (!cartId.value && !createIfNotExists) {
        this.loadingCart = false;
        return;
      }

      let failedLoad = false;
      if (cartId.value) {
        const existingCart = await apiGet<CartApiResponse>(
          `/basket/${cartId.value}`
        );
        if (existingCart?.id && existingCart?.statusId === 3) {
          cartId.value = existingCart.id;
          this.cartId = existingCart.id;
          this.cart = existingCart;
          Sentry.setContext('basket', {
            uuid: existingCart.id,
          });
        } else {
          cartId.value = null;
          this.cartId = '';
          this.cart = emptyCart;
          failedLoad = true;
        }
      }

      // creates a new cart if it doesn't exist
      if (!cartId.value || failedLoad) {
        const newCart = await apiPost<CartApiResponse>(
          '/basket'
        );
        if (newCart?.id) {
          cartId.value = newCart.id;
          this.cartId = newCart.id;
          this.cart = newCart;
          Sentry.setContext('basket', {
            uuid: newCart.id,
          });
        }
      }

      this.cartItems = this.cart.items.filter(customerSuccessItemFilter).map((i)=> i.quantity).reduce((a,b)=>a+b, 0);
      this.lastUpdate = Date.now();
      this.loadingCart = false;
    },
    async getCheckout() {
      const { apiGet } = useApiFetch();
      const cartId = useCookie('cartId', { maxAge: 3600 * 24 * 182, path: '/' + useRoute().path?.split('/').filter((str) => str.length).shift() });
      if (!cartId.value) {
        console.log('no cart id');
        return false;
      }
      this.loadingCheckout = true;
      const checkout = await apiGet<CheckoutApiResponse>(
        `/basket/${cartId.value}/checkout`, {}, false
      );
      if (checkout) {
        this.cart = checkout.basket;
        this.cartItems = this.cart.items.filter(customerSuccessItemFilter).map((i)=> i.quantity).reduce((a,b)=>a+b, 0);
        this.shippingAddress = checkout.shippingAddress;
        this.billingAddress = checkout.billingAddress;
        this.payerAddress = checkout.payerAddress;
        this.deliveryMethods = checkout.deliveryMethods;
        this.paymentMethods = checkout.paymentMethods as CheckoutPaymentMethod[];
        this.buyerCompanyName = checkout.buyerCompanyName;
        this.loadingCart = false;
        this.loadingCheckout = false;
        return true;
      }
      this.cartId = cartId.value;
      this.loadingCheckout = false;
      return false;
    },
    async updateDeliveryMethod(deliveryMethodId: number) {
      const { apiPost, handleApiError } = useApiFetch();
      const cartId = useCookie('cartId', { maxAge: 3600 * 24 * 182, path: '/' + useRoute().path?.split('/').filter((str) => str.length).shift() });
      if (cartId.value !== null && cartId.value !== undefined) {
        this.cartId = cartId.value;
      } else {
        return false;
      }
      if (!cartId.value) {
        return false;
      }
      const checkout = await apiPost<CheckoutApiResponse>(
        `/basket/${cartId.value}/delivery-method`,
        {
          id: deliveryMethodId,
        }
      );
      if (checkout) {
        this.deliveryMethods = checkout.deliveryMethods;
      } else {
        handleApiError();
      }
    },
    async updatePaymentMethod(paymentMethodId: number) {
      const { apiPost, handleApiError } = useApiFetch();
      const cartId = useCookie('cartId', { maxAge: 3600 * 24 * 182, path: '/' + useRoute().path?.split('/').filter((str) => str.length).shift() });
      if (cartId.value !== null && cartId.value !== undefined) {
        this.cartId = cartId.value;
      } else {
        return false;
      }
      const res = await apiPost<CheckoutApiResponse>(
        `/basket/${cartId.value}/payment-method`,
        {
          id: paymentMethodId,
        }
      );
      if (res) {
        this.cart = res.basket;
        this.paymentMethods = res.paymentMethods;
      } else {
        handleApiError();
      }
    },

    async updateComment(comment: string) {
      const { apiPost, handleApiError } = useApiFetch();
      const cartId = useCookie('cartId', { maxAge: 3600 * 24 * 182, path: '/' + useRoute().path?.split('/').filter((str) => str.length).shift() });
      if (cartId.value !== null && cartId.value !== undefined) {
        this.cartId = cartId.value;
      } else {
        return false;
      }
      const res = await apiPost<CheckoutApiResponse>(
        `/basket/${cartId.value}/comment`,
        { comment }
      );
      if (!res) {
        handleApiError();
      }
    },

    async updateShippingAddress(shippingForm: CheckoutAddressDetails) {
      const { apiPost, handleApiError } = useApiFetch();
      const cartId = useCookie('cartId', { maxAge: 3600 * 24 * 182, path: '/' + useRoute().path?.split('/').filter((str) => str.length).shift() });
      this.isLoading = true;
      if (!cartId.value) {
        return false;
      }
      const checkout = await apiPost<CheckoutApiResponse>(
        `/basket/${cartId.value}/delivery`,
        {
          companyName: this.shippingAddress.companyName,
          orgNo: this.shippingAddress.orgNo,
          email: shippingForm.email,
          firstName: shippingForm.firstName,
          lastName: shippingForm.lastName,
          // phone
          deliveryAddress: {
            line1: shippingForm.street,
            zip: shippingForm.zip,
            city: shippingForm.city,
            country: shippingForm.country,
          },
        }
      );
      if (checkout) {
        this.cart = checkout.basket;
        this.shippingAddress = checkout.shippingAddress;
        this.isLoading = false;
        return true;
      } else {
        handleApiError();
        this.isLoading = false;
        return false;
      }
    },
    async updateShippingAddressFromSalon(salonData?: UserProfileAddress) {
      const { apiPost, handleApiError } = useApiFetch();
      if (salonData) {
        const cartId = useCookie('cartId', { maxAge: 3600 * 24 * 182, path: '/' + useRoute().path?.split('/').filter((str) => str.length).shift() });
        if (!cartId.value) {
          return;
        }
        this.isLoading = true;
        const checkout = await apiPost<CheckoutApiResponse>(
          `/basket/${cartId.value}/delivery`,
          {
            email: this.shippingAddress.email,
            firstName: this.shippingAddress.firstName,
            lastName: this.shippingAddress.lastName,
            addressId: salonData.id,
            deliveryAddress: salonData,
          }
        );
        if (checkout) {
          this.cart = checkout.basket;
          this.shippingAddress = checkout.shippingAddress;
        } else {
          handleApiError();
        }
        this.isLoading = false;
      } else {
        handleApiError();
      }
    },

    async handleDiscountCode(discountCode: string, isDelete?: boolean) {
      const { apiPost, apiDelete } = useApiFetch();
      let res;
      if (isDelete) {
        res = await apiDelete<CheckoutApiResponse>(
          `/basket/${this.cartId}/discount-code`,
          {
            discountCode: discountCode,
          }
        );
      } else {
        res = await apiPost<CheckoutApiResponse>(
          `/basket/${this.cartId}/discount-code`,
          {
            discountCode: discountCode,
          }
        );
      }
      if (res) {
        this.isLoading = true;
        this.cart = res.basket;
        this.cartItems = this.cart.items.filter(customerSuccessItemFilter).map((i)=> i.quantity).reduce((a,b)=>a+b, 0);
        setTimeout(() => {
          this.isLoading = false;
        }, 500);
      }

      return res;
    },

    async saveOrderProposal(status: string, validUntil: number, comment: string) {
      const { apiPost, handleApiError } = useApiFetch();

      const res = await apiPost<UserProfile>('/order-proposals', {
        basketId: this.cartId,
        status,
        validUntil,
        comment,
      });

      if (res) {
        useUserStore().setUserProfile(res);
        return true;
      } else {
        handleApiError();
      }
    },

    async updateProductDiscount(
      lineNo: number,
      discount: string,
      quantity: number,
      priceListLocked = false
    ) {
      const { apiPost } = useApiFetch();

      return await apiPost('/products/discount', {
        basketId: this.cartId,
        lineNo,
        discount,
        quantity,
        priceListLocked,
      });
    },

    async deleteProductDiscount(lineNo: number) {
      const { apiDelete } = useApiFetch();

      return await apiDelete('/products/discount', {
        basketId: this.cartId,
        lineNo,
      });
    },

    async updateNewsletterConfirm(checked: boolean) {
      const { apiPut, handleApiError } = useApiFetch();
      const cartId = useCookie('cartId', { maxAge: 3600 * 24 * 182, path: '/' + useRoute().path?.split('/').filter((str) => str.length).shift() });
      const checkout = await apiPut<CheckoutApiResponse>(
        `/basket/${cartId.value}/newsletter`,
        {
          hasNewsletterSubscription: checked,
        }
      );
      if (checkout) {
        this.cart = checkout.basket;
      } else {
        handleApiError();
      }
    },

    deleteCart() {
      const cartId = useCookie('cartId', { maxAge: 3600 * 24 * 182, path: '/' + useRoute().path?.split('/').filter((str) => str.length).shift() });
      cartId.value = null;
      this.cartItems = 0;
      this.cart = emptyCart;
      this.cartId = '';
      if (!useUserStore().isLoggedIn) {
        return;
      }
      /*const { apiDelete } = useApiFetch();
      try {
        await apiDelete('/basket/saved-basket');
      } catch (e) {
        console.error(e);
      }*/
    },
    async completePendingPurchase(basketId: string) {
      const uiStore = useUiStore();
      const { apiPost, handleApiError, lastError, lastErrorCode } = useApiFetch();
      this.loadingPurchase = true;
      this.hasAttemptedPurchase = true;
      const res = await apiPost<CheckoutApiResponse>(`/basket/${basketId}/purchase-pending`);
      const { manualUrl } = useLinkReplacer();
      if (res !== null) {
        await navigateTo(manualUrl(`/checkout/success/${basketId}`));
      } else {
        this.loadingPurchase = false;
        if (lastErrorCode.value === 422) {
          if (lastError.value?.message) {
            uiStore.setTemporaryError(lastError.value.message);
          } else {
            const { $t } = useNuxtApp();
            uiStore.setTemporaryError($t('apiError.unknown'));
          }
        } else {
          handleApiError();
        }
      }

      this.loadingPurchase = false;
    },
    async completePurchase() {
      const uiStore = useUiStore();
      const { apiPost, handleApiError, lastError, lastErrorCode } = useApiFetch();
      this.loadingPurchase = true;
      this.hasAttemptedPurchase = true;

      /**
       * Algolia tracking. Check if we have a query id connected to this product
       * For now, we use partNo, they should be interchangeable
       */
      const track: string[] = [];
      const trackWqueryId1: string[] = [];
      const trackWqueryId2: {queryID: string}[] = [];
      this.cart.items.forEach((item) => {
        const queryID = localStorage.getItem(`objectID-${item.partNo}`);
        if (queryID) {
          trackWqueryId1.push(item.partNo);
          trackWqueryId2.push({ queryID });
        } else {
          track.push(item.partNo);
        }
      });
      const browserTrackingCookie = useStatefulCookie('randomId', { maxAge: 3600 * 24 * 365 });
      if (trackWqueryId1.length) {
        aa('purchasedObjectIDsAfterSearch', {
          userToken: browserTrackingCookie.value || '',
          eventName: 'Tracked Product Purchase',
          index: '89fce283-7c9a-49f5-b3db-9f492daf4daf-nb-NO-products',
          objectIDs: trackWqueryId1,
          objectData: trackWqueryId2,
          queryID: '',
        });
      }
      if (track.length) {
        aa('purchasedObjectIDs', {
          userToken: browserTrackingCookie.value || '',
          eventName: 'Product Purchase',
          index: '89fce283-7c9a-49f5-b3db-9f492daf4daf-nb-NO-products',
          objectIDs: track,
        });
      }

      const res = await apiPost<PurchaseResponse>(`/basket/${this.cartId}/purchase`);
      const { manualUrl } = useLinkReplacer();

      if (res !== null) {
        if (res.allowPointsPurchase && res.delayedUntil) {
          await navigateTo(manualUrl(`/checkout/pointshop/${res.basketId}?delayedUntil=${encodeURIComponent(res.delayedUntil)}`));
        } else {
          await navigateTo(manualUrl(`/checkout/success/${res.basketId}`));
        }

      } else {
        this.loadingPurchase = false;
        if (lastErrorCode.value === 422) {
          if (lastError.value?.message) {
            uiStore.setTemporaryError(lastError.value.message);
          } else {
            const { $t } = useNuxtApp();
            uiStore.setTemporaryError($t('apiError.unknown'));
          }
        } else {
          handleApiError();
        }
      }

      this.loadingPurchase = false;
    },

    /**
     * Adds or updates cart
     * @param partNo
     * @param quantity - set zero to remove
     * @param lineNo - if updating cart, otherwise -1
     * @param openAdded - if "added to cart" modal should open
     * @param product - we need the product for tracking
     * @param limitedProduct - if we don't have entire product data
     * that makes it harder. If we could refactor cart items to compatible with product,
     * this would work better, but it's a big refactor
     * @param objectID - objectID from algolia, if we have it, used for tracking
     * @param priceListLocked - objectID from algolia, if we have it, used for tracking
     * @param crossDocking
     */
    async updateCart(
      partNo: string,
      quantity = 1,
      lineNo = -1,
      openAdded = false,
      product: CartApiItem | ProductVariantModel | OrderProduct | null = null,
      limitedProduct = false,
      objectID: string = '',
      priceListLocked = false,
      toggleCrossDocking = false
    ) {
      const uiStore = useUiStore();
      const userStore = useUserStore();
      const route = useRoute();
      this.isLoading = true;
      const modifiedQuantity = quantity;
      const { apiPost, lastError } = useApiFetch();

      // Error message if sales rep / customer success user is not impersonating
      if ((userStore.isCustomerSuccessUser || userStore.isSalesRepUser) && !userStore.userProfile.impersonatingCompany && !this.cartId) {
        const { $t } = useNuxtApp();
        uiStore.setTemporaryError($t('user.impersonateToAddToCart'));
        return true;
      }

      // when adding, check if already in cart.
      if (lineNo === -1) {
        const findLineNo = this.cart.items.find((f) => f.partNo === partNo && f.priceListLocked === priceListLocked && !f.parentLineNo && f.infoTypes?.filter(i => i.name !== NorceInfoTypesCodes.SalesRepDiscount && i.name !== NorceInfoTypesCodes.CrossDocking && i.name !== NorceInfoTypesCodes.DataLayer).length === 0);
        if (findLineNo) {
          lineNo = findLineNo.lineNo;
          if (quantity === -1) {
            quantity = findLineNo.quantity - 1;
          } else if (quantity > 0) {
            quantity = findLineNo.quantity + quantity;
          }
        }
      }

      const payload = {
        items: [
          {
            partNo,
            quantity,
            priceListLocked,
          },
        ],
      } as CartApiPayload;

      if (lineNo > -1) {
        payload.items[0].lineNo = lineNo;
      }

      let beforeChange = null;
      if (lineNo >= 0) {
        beforeChange = this.cart.items.find((f) => f.lineNo === lineNo);
      }

      if (this.isBuyingUpsellProduct) {
        payload.items[0].isCrossSell = this.isBuyingUpsellProduct;
      }

      if (toggleCrossDocking) {
        const currentValue = this.cart.items
          .find((f) => f.lineNo === lineNo)
          ?.infoTypes
          ?.filter((i) => i.name === NorceInfoTypesCodes.CrossDocking)[0];
        payload.items[0].info = [
          { code: NorceInfoTypesCodes.CrossDocking, value: currentValue?.value == 'true' ? '0' : '1' },
        ];
      }

      const update = await apiPost<CartApiResponse>(
        `/basket/items/${this.cartId}`,
        payload
      );
      if (update) {
        if (update.messages) {
          update.messages.forEach((f) => {
            uiStore.setTemporaryError(f.split(':')[1]);
          });
        }
        if (product) {
          let sendQuantity = quantity;
          if (beforeChange) {
            sendQuantity = quantity - beforeChange.quantity;
          }
          let trackEvent = 'add_to_cart';
          if (sendQuantity < 0) {
            sendQuantity = Math.abs(sendQuantity);
            trackEvent = 'remove_from_cart';
          }

          sendDataLayer(
            trackEvent,
            [ createDataLayerItem(product, sendQuantity)]
          );
        }
        // Algolia track
        if (objectID !== '') {
          const browserTrackingCookie = useStatefulCookie('randomId', { maxAge: 3600 * 24 * 365 });
          const queryID = localStorage.getItem('queryID') || '';
          const insightPayload = {
            userToken: browserTrackingCookie.value,
            eventName: 'Product Added To Cart',
            objectIDs: [ objectID ],
            index: '89fce283-7c9a-49f5-b3db-9f492daf4daf-nb-NO-products',
          } as any;
          if (queryID !== '') {
            insightPayload.objectData = [
              {
                queryID,
              },
            ];
          }

          // adds to local storage, to track after purchase
          localStorage.setItem(`objectId-${objectID}`, queryID);
          if (queryID !== '') {
            aa('addedToCartObjectIDsAfterSearch', insightPayload);
          } else {
            aa('addedToCartObjectIDs', insightPayload);
          }
        } else {
          // console.log('no object id');
        }

        this.cart = update;
        this.cartItems = this.cart.items.filter(customerSuccessItemFilter).map((i)=> i.quantity).reduce((a,b)=>a+b, 0);

        if (this.cartId === '') {
          const cartId = useCookie('cartId', { maxAge: 3600 * 24 * 182, path: '/' + useRoute().path?.split('/').filter((str) => str.length).shift() });
          cartId.value = this.cart.id;
          this.cartId = this.cart.id;
        }

        this.isLoading = false;

        if (uiStore.currentlyEditingItem) {
          const item = this.cart.items.find((item) => item.lineNo === uiStore.currentlyEditingItem?.lineNo);
          if (item) {
            uiStore.setCurrentlyEditingItem(item);
          }
        }

        // Product added
        if (quantity >= 1) {
          this.justAddedPartNo = partNo;
        }

        if (openAdded && quantity >= 1) {
          if (product && !limitedProduct) {
            // @ts-ignore
            this.currentlyBuying = product;
            this.currentlyBuyingPriceListLocked = priceListLocked;
            this.currentlyBuyingQuantity = modifiedQuantity;
          }
          const shouldShowAddedToCart = route.name !== 'checkout';
          uiStore.setShowAddedToCart(shouldShowAddedToCart);
          uiStore.setShowQuickBuy(false);
        }
      } else {
        // Failed to update cart, most likely because not enough in stock
        const { $t } = useNuxtApp();
        uiStore.setTemporaryError(lastError?.value?.message ?? $t('addedToCart.fail'));
        // reloads the cart so we know it's ok
        await this.getCart(false);
        this.isLoading = false;
      }
      if (this.isBuyingUpsellProduct) {
        this.isBuyingUpsellProduct = false;
      }
      return true;
    },
    /**
     *
     * @param items
     * @param openAdded
     * @param overwrite - if true, quantities already in cart are overwritten, else changed
     */
    async addMultipleItemsToCart(items: multiAddToCart[], openAdded: boolean, overwrite = false) {
      const uiStore = useUiStore();
      const route = useRoute();
      this.isLoading = true;
      const { apiPost, lastError } = useApiFetch();
      // when adding, check if already in cart.
      const payloadItems = items.map(item => {
        const findLineNo = this.cart.items.find((f) => f.partNo === item.partNo && !f.priceListLocked && !f.parentLineNo);
        let newQuantity = item.quantity;

        if (findLineNo && !overwrite) {
          if (newQuantity === -1) {
            newQuantity = findLineNo.quantity - 1;
          } else if (newQuantity > 0) {
            newQuantity = findLineNo.quantity + newQuantity;
          }
        }
        return {
          ...item,
          quantity: newQuantity,
          lineNo: findLineNo?.lineNo ?? -1,
        };
      });

      const payload = {
        items: payloadItems.map(item => ({ partNo: item.partNo, quantity: item.quantity, lineNo: item.lineNo })),
      };

      const update = await apiPost<CartApiResponse>(
        `/basket/items/${this.cartId}`,
        payload
      );
      if (update) {
        payloadItems.forEach(item => {
          if (item.product) {
            let sendQuantity = item.quantity;
            let beforeChange = null;
            if (item.lineNo >= 0) {
              beforeChange = this.cart.items.find((f) => f.lineNo === item.lineNo);
            }
            if (beforeChange) {
              sendQuantity = item.quantity - beforeChange.quantity;
            }
            let trackEvent = 'add_to_cart';
            if (sendQuantity < 0) {
              sendQuantity = Math.abs(sendQuantity);
              trackEvent = 'remove_from_cart';
            }

            sendDataLayer(
              trackEvent,
              [ createDataLayerItem(item.product, sendQuantity)]
            );
          }
        });

        this.cart = update;

        this.cartItems = this.cart.items.filter(customerSuccessItemFilter).map((i)=> i.quantity).reduce((a,b)=>a+b, 0);

        if (this.cartId === '') {
          const cartId = useCookie('cartId', { maxAge: 3600 * 24 * 182, path: '/' + useRoute().path?.split('/').filter((str) => str.length).shift() });
          cartId.value = this.cart.id;
          this.cartId = this.cart.id;
        }

        this.isLoading = false;

        if (uiStore.currentlyEditingItem) {
          const item = this.cart.items.find((item) => item.lineNo === uiStore.currentlyEditingItem?.lineNo);
          if (item) {
            uiStore.setCurrentlyEditingItem(item);
          }
        }

        if (openAdded && payloadItems.length === 1) {
          if (items[0].product) {
            // @ts-ignore
            this.currentlyBuying = items[0].product;
            this.currentlyBuyingQuantity = items[0].quantity;
          }
          const shouldShowAddedToCart = route.name !== 'checkout';
          uiStore.setShowAddedToCart(shouldShowAddedToCart);
          uiStore.setShowQuickBuy(false);
        }
      } else {
        // Failed to update cart, most likely because not enough in stock
        const { $t } = useNuxtApp();
        uiStore.setTemporaryError(lastError?.value?.message ?? $t('addedToCart.fail'));
        // reloads the cart so we know it's ok
        await this.getCart(false);
      }
      if (this.isBuyingUpsellProduct) {
        this.isBuyingUpsellProduct = false;
      }
      return true;
    },
    /**
     * This method is similar to addMultipleItemsToCart, but you have to deal with lineNo:s manually
     * It's used for the "fast order" functionality
     */
    async updateMultipleItemsWithLineNo({ items, shouldMerge }: { items: UpdateWithLineNo[], shouldMerge: boolean }) {
      const { apiPost, handleApiError } = useApiFetch();
      this.isLoading = true;
      const endpoint = shouldMerge ? `/basket/quick/${this.cartId}` : '/basket/quick';

      const res = await apiPost<CartApiResponse>(
        endpoint,
        { items }
      );
      if (res) {
        this.cart = res;

        this.cartItems = this.cart.items.filter(customerSuccessItemFilter).map((i)=> i.quantity).reduce((a,b)=>a+b, 0);

        if (this.cartId === '' || !shouldMerge) {
          const cartId = useCookie('cartId', { maxAge: 3600 * 24 * 182, path: '/' + useRoute().path?.split('/').filter((str) => str.length).shift() });
          cartId.value = this.cart.id;
          this.cartId = this.cart.id;
        }

        this.isLoading = false;
        if (res.messages) {
          return res.messages;
        }
      } else {
        handleApiError('quickorder', false, true, true);
        this.isLoading = false;
      }
      return [];
    },

    async updateCustomerSuccessProduct(payload: { partNo: string; message: string }) {
      const { apiPost, handleApiError } = useApiFetch();
      const res = await apiPost<CartApiResponse>(`/customer-success/basket/${this.cartId}/items`, {
        partNo: payload.partNo,
        message: payload.message,
      });

      if (res) {
        this.cart = res;
      } else {
        handleApiError();
      }
    },
    async updateCustomerSuccessInfoTypes(payload: { code: string; checked: boolean }) {
      const { apiPut, handleApiError } = useApiFetch();
      const res = await apiPut<CartApiResponse>(`/customer-success/basket/${this.cartId}`, {
        code: payload.code,
        value: payload.checked,
      });

      if (res) {
        this.cart = res;
        return true;
      } else {
        handleApiError();
        return false;
      }
    },
    async acceptOrderProposal(orderProposal: OrderProposal, mergeActiveBasket: boolean) {
      const { apiPost, handleApiError } = useApiFetch();
      const res = await apiPost<CartApiResponse>('/order-proposals/accept', {
        basketId: orderProposal.basketId,
        mergeActiveBasket: mergeActiveBasket ? this.cart.id : null,
      });
      if (res) {
        const cartId = useCookie('cartId', { maxAge: 3600 * 24 * 182, path: '/' + useRoute().path?.split('/').filter((str) => str.length).shift() });
        cartId.value = res.id;
        this.cartId = res.id;
        this.cart = res;
        this.cartItems = this.cart.items.filter(customerSuccessItemFilter).map((i)=> i.quantity).reduce((a,b)=>a+b, 0);
        return true;
      } else {
        handleApiError();
      }
    },
    async updateGlobalDiscount(discount: number) {
      const { apiPut, handleApiError } = useApiFetch();
      const cartId = useCookie('cartId', {
        maxAge: 3600 * 24 * 182,
        path: '/' + useRoute().path?.split('/').filter((str) => str.length).shift()
      });
      if (cartId.value !== null && cartId.value !== undefined) {
        this.cartId = cartId.value;
      } else {
        return false;
      }
      const res = await apiPut(
        `/basket/quick/${cartId.value}/global-discount`,
        { discount }
      );
      if (!res) {
        handleApiError();
      }
    },
    async addPreviewBasketToCart() {
      const uiStore = useUiStore();
      const userStore = useUserStore();
      if (uiStore.currentlyPreviewing?.cart) {
        if ((userStore.isCustomerSuccessUser || userStore.isSalesRepUser) && userStore.userProfile.company.id !== uiStore.currentlyPreviewing.savedCart.companyId) {
          const { handleApiError } = useApiFetch();
          const res = await userStore.impersonateCompany(uiStore.currentlyPreviewing.savedCart.companyId.toString(), false);
          if (!res) {
            handleApiError();
          }
        }
        const cartId = useCookie('cartId', { maxAge: 3600 * 24 * 182, path: '/' + useRoute().path?.split('/').filter((str) => str.length).shift() });
        cartId.value = uiStore.currentlyPreviewing.cart.id;
        this.cartId = uiStore.currentlyPreviewing.cart.id;
        this.cart = uiStore.currentlyPreviewing.cart;
        this.cartItems = this.cart.items.filter(customerSuccessItemFilter).map((i)=> i.quantity).reduce((a,b)=>a+b, 0);

        return true;
      }
      return false;
    },
    changeDebouncedQueue(product: multiAddToCart) {
      const exist = this.deboucedAddToCart.findIndex((f)=> f.partNo === product.partNo);
      if (exist >= 0) {
        this.deboucedAddToCart[exist].quantity = product.quantity;
      } else {
        this.deboucedAddToCart.push(product);
      }
      clearTimeout(this.deboucedTimer);
      this.deboucedTimer = setTimeout(()=> {
        this.sendDebouncedQueue();
      }, 3000);
    },
    sendDebouncedQueue() {
      clearTimeout(this.deboucedTimer);
      if (this.deboucedAddToCart.length > 0) {
        this.addMultipleItemsToCart(this.deboucedAddToCart, false, true);
        this.deboucedAddToCart = [];
      }
    },
  },
});
