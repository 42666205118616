import { defineStore } from 'pinia';
import { CartApiItem, CartApiResponse, OrderProposal, SavedCart } from '~/constants/types/norce';

export type ListImageSizes = 'lg' | 'sm' | '';

type FormErrors = {
  message?: string,
  errors?: { [key: string]: string[]; },
  context?: string,
}

export const useUiStore = defineStore('uiStore', {
  state: () => ({
    isMobile: true, // a tablet in portrait mode is also considered a mobile
    isTabletPortrait: false,

    showMobileSearch: false,
    showDesktopSearch: false,
    searchOpenedOnceMob: false,
    searchOpenedOnceDesk: false,
    sideMenuParents: [] as string[], // expanded menus
    customerModalOpen: false,
    listImageSize: '' as ListImageSizes,
    quickBuyCloseAfterAdd: false,

    // modals
    showModalBg: false,
    showSideMenu: false,
    showDesktopMenu: false,
    showQuickBuy: false,
    showMiniCart: false,
    showFaq: false,

    showSiteNotice: false,
    showSizeMenu: false,
    showReviews: false,
    showWashModal: false,
    showAddedToCart: false,
    showCheckoutHelp: false,
    showValidateCheckoutModal: false,
    showDeclineOrderProposalModal: false,
    showOrderProposalCartModal: false,
    showPreviewCartModal: false,
    showEditProductDiscountModal: false,
    showSigninModal: false,
    showLogInAsCustomer: false,
    showImpersonateModal: false,
    stayOnPageAfterLogin: false,
    showFilters: false,
    showPriceHistory: false,
    showNotify: false,
    notifyModalPartNo: '',

    showScanner: false,

    searchIsLoading: true,
    ignoreNavigationParent: false,
    tempErrorMsg: '' as string | null, // shows a modal error message
    tempMsg: '' as string | null, // shows a modal message
    tempMsgType: '' as string | null, // modal message type
    formErrorMsg: {} as FormErrors,
    currentlyDeclining: null as OrderProposal | null,
    currentlyViewing: null as { cart: CartApiResponse, orderProposal: OrderProposal } | null,
    currentlyPreviewing: null as { cart: CartApiResponse, savedCart: SavedCart } | null,
    currentlyEditingItem: null as CartApiItem | null,
  }),
  getters: {
    isParentOfOpenMenu: (state) => (uid: string) => {
      return state.sideMenuParents.includes(uid);
    },
  },
  actions: {
    // Shows a temporary error message. Set fadeTime = 0 so user have to click it to get rid of
    setTemporaryError(payload: string, fadeTime = 0) {
      this.tempErrorMsg = payload;

      if (fadeTime > 0) {
        setTimeout(() => {
          this.tempErrorMsg = null;
        }, fadeTime);
      }
    },
    // Shows a temporary success message. Set fadeTime = 0 so user have to click it to get rid of
    setTemporarySuccessMsg(payload: string, type = 'success', fadeTime = 8000) {
      this.tempMsg = payload;
      this.tempMsgType = type;

      if (fadeTime > 0) {
        setTimeout(() => {
          this.tempMsg = null;
        }, fadeTime);
      }
    },
    setShowSigninModal(payload: boolean, stayOnPageAfterLogin = false) {
      this.stayOnPageAfterLogin = stayOnPageAfterLogin;
      if (payload) {
        this.showModalBg = true;
      }
      this.showSigninModal = payload;
    },
    setShowLogInAsCustomer(payload: boolean) {
      if (payload) {
        this.showModalBg = true;
      }
      this.showLogInAsCustomer = payload;
    },
    setImpersonateModal(payload: boolean) {
      if (payload) {
        this.showModalBg = true;
      }
      this.showImpersonateModal = payload;
    },
    setShowDeclineOrderProposalModal(payload: boolean) {
      if (payload) {
        this.showModalBg = true;
      }
      this.showDeclineOrderProposalModal = payload;
    },
    setShowOrderProposalCartModal(payload: boolean) {
      this.showModalBg = payload ? true : false;
      this.showOrderProposalCartModal = payload;
    },
    setCurrentlyViewing(payload?: {cart: CartApiResponse, orderProposal: OrderProposal}) {
      this.currentlyViewing = payload ?? null;
    },
    setPreviewCartModal(payload: boolean) {
      this.showModalBg = payload;
      this.showPreviewCartModal = payload;
    },
    setCurrentlyPreviewingCart(payload?: {cart: CartApiResponse, savedCart: SavedCart}) {
      this.currentlyPreviewing = payload ?? null;
    },
    setShowEditProductDiscountModal(payload: boolean) {
      if (payload) {
        this.showModalBg = true;
      }
      this.showEditProductDiscountModal = payload;
    },
    setCurrentlyDeclining(payload: OrderProposal) {
      this.currentlyDeclining = payload;
    },
    setCurrentlyEditingItem(payload: CartApiItem) {
      this.currentlyEditingItem = payload;
    },
    setShowAddedToCart(payload: boolean) {
      this.showModalBg = payload;
      this.showAddedToCart = payload;

      setTimeout(() => {
        if (this.showAddedToCart) {
          this.showAddedToCart = false;
          this.showModalBg = false;
        }
      }, 5000);
    },
    setShowCheckoutHelp(payload: boolean) {
      if (payload) {
        this.showModalBg = true;
      }
      this.showCheckoutHelp = payload;
    },
    setShowValidateCheckoutModal(payload: boolean) {
      this.showModalBg = payload;
      this.showValidateCheckoutModal = payload;
    },
    setShowSizeMenu(payload: boolean) {
      if (payload) {
        this.showModalBg = true;
      }
      this.showSizeMenu = payload;
    },
    setShowReviews(payload: boolean) {
      if (payload) {
        this.showModalBg = true;
      }
      this.showReviews = payload;
    },
    setShowFaq(payload: boolean) {
      if (payload) {
        this.showModalBg = true;
      }
      this.showFaq = payload;
    },
    setShowWashModal(payload: boolean) {
      if (payload) {
        this.showModalBg = true;
      }
      this.showWashModal = payload;
    },
    setShowPriceHistory(payload: boolean) {
      if (payload) {
        this.showModalBg = true;
      }
      this.showPriceHistory = payload;
    },
    setShowNotify(payload: boolean) {
      if (payload) {
        this.showModalBg = true;
      }
      this.showNotify = payload;
    },
    setShowSiteNotice(payload: boolean) {
      if (payload) {
        this.showModalBg = true;
      }
      this.showSiteNotice = payload;
    },
    setShowModalBg(payload: boolean) {
      this.showModalBg = payload;
    },
    closeAllModal() {
      this.showModalBg = false;
      this.showSideMenu = false;
      this.showMiniCart = false;
      this.showQuickBuy = false;
      this.showSiteNotice = false;
      this.showSizeMenu = false;
      this.showReviews = false;
      this.showWashModal = false;
      this.showAddedToCart = false;
      this.showCheckoutHelp = false;
      this.showValidateCheckoutModal = false;
      this.showDeclineOrderProposalModal = false;
      this.showOrderProposalCartModal = false;
      this.showPreviewCartModal = false;
      this.showEditProductDiscountModal = false;
      this.showSigninModal = false;
      this.showImpersonateModal = false;
      this.showLogInAsCustomer = false;
      this.showMobileSearch = false;
      this.showFilters = false;
      this.showFaq = false;
      this.showPriceHistory = false;
      this.showNotify = false;
      this.showDesktopMenu = false;
      this.showDesktopSearch = false;
      this.showScanner = false;

      // this is used to show/hide desktop menu
      this.sideMenuParents = [];
    },
    setShowMiniCart(payload: boolean) {
      if (payload) {
        this.showModalBg = true;
      }
      this.showMiniCart = payload;
    },
    setShowFilters(payload: boolean) {
      if (payload) {
        this.showModalBg = true;
      }
      this.showFilters = payload;
    },
    /**
     *
     * @param payload
     * @param hideAfter - if true it doesn't go on to "just added" modal
     */
    setShowQuickBuy(payload: boolean, hideAfter = false) {
      if (payload) {
        this.showModalBg = true;
      }
      this.quickBuyCloseAfterAdd = hideAfter;
      this.showQuickBuy = payload;
    },
    setIsMobile(payload: boolean) {
      this.isMobile = payload;
    },
    setisTabletPortrait(payload: boolean) {
      this.isTabletPortrait = payload;
    },
    setShowSideMenu(payload: boolean) {
      if (payload) {
        this.showModalBg = true;
      }
      this.showSideMenu = payload;
    },
    setShowMobileSearch(payload: boolean) {
      this.showMobileSearch = payload;
    },
    openSideMenuWith({ uid, ignoreParent }: { uid: string; ignoreParent?: boolean} ) {
      this.sideMenuParents = [ uid ];
      this.ignoreNavigationParent = ignoreParent ?? false;
      this.showSideMenu = true;
      this.showModalBg = true;
    },
    openDesktopMenuWith({ uid, ignoreParent }: { uid: string; ignoreParent?: boolean} ) {
      this.sideMenuParents = [ uid ];
      this.ignoreNavigationParent = ignoreParent ?? false;
      this.showDesktopMenu = true;
    },
    addSideMenuParent(payload: string, resetBelow = '') {
      if (resetBelow !== '') {
        const index = this.sideMenuParents.findIndex((f)=>f===resetBelow);
        this.sideMenuParents.splice(index + 1, this.sideMenuParents.length - (index + 1) );
      }
      this.sideMenuParents.push(payload);
    },
    closeTopSideMenu() {
      this.sideMenuParents.pop();
    },
    closeSideMenu() {
      this.sideMenuParents = [];
      this.ignoreNavigationParent = false;
      this.showSideMenu = false;
      this.showModalBg = false;
    },
    closeDesktopMenu() {
      this.sideMenuParents = [];
      this.showDesktopMenu = false;
    },
    setCustomerModalOpen(payload: boolean) {
      this.customerModalOpen = payload;
    },
    setListImageSize(payload: ListImageSizes) {
      this.listImageSize = payload;
    },
    setShowScanner(payload: boolean) {
      this.showScanner = payload;
      if (payload) {
        this.showModalBg = true;
      }
    },
    setFormErrorMsg(payload: FormErrors|string, key?: string) {
      if (typeof payload === 'string') {
        this.formErrorMsg = { message: payload };
      } else {
        this.formErrorMsg = payload;
      }
      if (key) {
        this.formErrorMsg.context = key;
      }
    },
    clearFormErrorMsg() {
      this.formErrorMsg = {};
    },
  },
});
